import React from "react";
import styled from "styled-components";
import { colors, breakpoints, spacers } from "../styles/variables";
import Title from "./Title";
import LeadHeading from "./LeadHeading";
import Paragraph from "./Paragraph";

const FooterDiv = styled.footer`
  background: ${colors.red};
  padding: ${spacers.mobile}px;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding: ${spacers.tablet}px;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    padding: ${spacers.desktop}px;
  }

  > div {
    max-width: 900px;
    margin: 0 auto;

    * {
      color: white;
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterDiv>
      <div>
        <Title>Kontakt</Title>
        <LeadHeading>Prof. Dr. med. Wolfgang Korte</LeadHeading>
        <Paragraph>
          Haemostase- & Haemophilie-Zentrum
          <br />
          Frohbergstrasse 3<br />
          Postfach 1217
          <br />
          9001 St. Gallen
          <br />
          Schweiz
          <br />
          <br />
          <a href="mailto:info@hicc.ch">info@hicc.ch</a>
        </Paragraph>
      </div>
    </FooterDiv>
  );
};

export default Footer;
