import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const PStyled = styled.p`
  color: ${colors.text};
  margin-bottom: 20px;
  font: 16px / 24px "Helvetica Neue Light";

  b,
  strong {
    font-family: "Helvetica Neue Bold";
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    font: 20px / 30px "Helvetica Neue Light";
    margin-bottom: 30px;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    font: 24px /34px "Helvetica Neue Light";
    margin-bottom: 40px;
  }
`;

const Paragraph: React.FC = ({ children }) => {
  return <PStyled>{children}</PStyled>;
};

export default Paragraph;
