import React from "react";
import styled from "styled-components";

const SmallTextStyle = styled.p`
  font: 14px / 17px "Helvetica Neue Light";
  text-align: center;
`;

const SmallText: React.FC = ({ children }) => {
  return <SmallTextStyle>{children}</SmallTextStyle>;
};

export default SmallText;
