import React, { useState } from "react";
import styled from "styled-components";
import { colors, breakpoints } from "../styles/variables";

interface NavToggleProps {
  isNavOpen: boolean;
  setNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toggle = styled.div`
  width: 60px;
  height: 38px;
  margin: auto 0;
  transition: margin-right 700ms;
  &:hover {
    cursor: pointer;
  }

  > div {
    height: 10px;
    margin: 2px 0;
    background: ${colors.title};
    width: 100%;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: 80px;
    height: 58px;
    > div {
      margin: 7px 0;
    }
  }

  div:first-of-type,
  div:last-of-type,
  div:nth-of-type(2) {
    transition: all 700ms;
  }

  div:nth-of-type(2) {
    transition: all 400ms;
  }
  &.navIsOpen {
    div:first-of-type {
      transform: rotate(45deg) translatey(17px);
      margin-right: -20px;
    }
    div:nth-of-type(2) {
      background: ${colors.grey};
    }
    div:last-of-type {
      transform: rotate(-45deg) translatey(-17px);
      margin-right: -20px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    &.navIsOpen {
      div:first-of-type {
        transform: rotate(45deg) translatey(24px);
      }
      div:nth-of-type(2) {
        background: ${colors.grey};
      }
      div:last-of-type {
        transform: rotate(-45deg) translatey(-24px);
      }
    }
  }
`;

const NavToggle: React.FC<NavToggleProps> = ({ isNavOpen, setNavOpen }) => {
  return (
    <Toggle
      onClick={() => {
        setNavOpen(!isNavOpen);
      }}
      className={isNavOpen ? "navIsOpen" : ""}
    >
      <div></div>
      <div></div>
      <div></div>
    </Toggle>
  );
};

export default NavToggle;
