import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/variables";
import Title from "../components/Title";

interface LongTextDivProps {
  background: string;
  titel: string;
}

const LongTextDiv = styled.div<LongTextDivProps>`
  background: ${(props) => props.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacers.desktop}px ${spacers.mobile}px;

  section {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    > div {
      width: 100%;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: ${spacers.desktop}px ${spacers.tablet}px;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    padding: 0;

    section {
      margin-top: 90px;
      margin-bottom: 128px;
    }
  }
`;

const LongText: React.FC<LongTextDivProps> = ({
  background,
  titel,
  children,
}) => {
  return (
    <LongTextDiv background={background}>
      <section>
        <Title>{titel}</Title>
        <div>{children}</div>
      </section>
    </LongTextDiv>
  );
};

export default LongText;
