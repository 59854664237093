import React from "react";
import styled from "styled-components";
import { colors, spacers } from "../styles/variables";
import SmallText from "../components/SmallText";
import { Link } from "gatsby";

const ImpressumDiv = styled.footer`
  background: ${colors.title};
  padding: ${spacers.mobile}px;

  > div {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: white;

    a {
      color: white;
    }
  }
`;

const Impressum: React.FC = () => {
  return (
    <ImpressumDiv>
      <div>
        <SmallText>
          Copyright ©2007-2024 HICC<sup>®</sup>,{" "}
          <Link to="/datenschutz/">Datenschutz</Link>
        </SmallText>
      </div>
    </ImpressumDiv>
  );
};

export default Impressum;
