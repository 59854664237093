import React from "react";
import styled from "styled-components";
import { colors, breakpoints, heights, spacers } from "../styles/variables";
import { Link } from "gatsby";

interface NavigationProps {
  isNavOpen: boolean;
  setNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Nav = styled.nav<NavigationProps>`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  top: ${heights.header.mobile}px;
  left: 0;
  right: 0;
  z-index: 1;
  background: ${colors.title};
  top: ${(props) =>
    props.isNavOpen ? heights.header.mobile + "px" : "-100vh"};
  transition: 700ms;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    top: ${(props) =>
    props.isNavOpen ? heights.header.desktop + "px" : "-100vh"};
  }

  div > a {
    text-transform: uppercase;
    font: 24px / 29px "Helvetica Neue Light";
    color: white;
    text-decoration: none;
    display: block;
    padding: ${spacers.mobile}px;
  }

  div {
    width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid ${colors.grey};

    &:first-of-type {
      border-top: 1px solid ${colors.title};
    }
    &:hover {
      background: ${colors.grey};
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    div {
      min-height: 94px;
      text-align: center;
      a {
        font: 30px / 36px "Helvetica Neue Light";
      }
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    div {
      height: 110px;
      margin: 0 auto;
      max-width: 900px;
      text-align: center;

      &:hover {
        background: ${colors.title};

        a {
          text-decoration: underline;
        }
      }

      a {
        font: 34px /41px "Helvetica Neue Light";
      }
    }
  }

  /*.is-active a {
    color: green;
  }
  ---------------
  # IS ACTIVE STYLING
  ---------------
  */
`;

const Navigation: React.FC<NavigationProps> = ({ isNavOpen, setNavOpen }) => {
  return (
    <Nav isNavOpen={isNavOpen}>
      <div>
        <Link to="/#Home" onClick={() => setNavOpen(!isNavOpen)}>
          Home
        </Link>
      </div>
      <div>
        <Link to="/#Workshop" onClick={() => setNavOpen(!isNavOpen)}>
          Hämostaseologischer Intensivworkshop
        </Link>
      </div>
      <div>
        <Link to="/#WorkshopSG" onClick={() => setNavOpen(!isNavOpen)}>
          Workshop St.Gallen 2024
        </Link>
      </div>

      <div>
        <a href="https://hicc-berlin.com/" target="_blank">
          Workshop Berlin 2024
        </a>
      </div>
      <div>
        <Link to="/#Koordinatoren" onClick={() => setNavOpen(!isNavOpen)}>
          Koordinatoren
        </Link>
      </div>
      <div>
        <Link to="/#Sponsoren" onClick={() => setNavOpen(!isNavOpen)}>
          Sponsoren
        </Link>
      </div>
      <div>
        <Link to="/#Kontakt" onClick={() => setNavOpen(!isNavOpen)}>
          Kontakt
        </Link>
      </div>
    </Nav>
  );
};

export default Navigation;
