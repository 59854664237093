import React from "react";
import styled from "styled-components";
import { breakpoints, spacers, colors } from "../styles/variables";

const StyledText = styled.h3`
  font: 24px / 29px "Helvetica Neue Bold";
  margin-bottom: 12px;
  color: ${colors.text};
  max-width: 800px;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    font: 29px / 36px "Helvetica Neue Bold";
    margin-bottom: ${spacers.mobile}px;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    font: 34px / 41px "Helvetica Neue Bold";
    margin-bottom: 20px;
  }
`;

const LeadHeading: React.FC = ({ children }) => {
  return <StyledText>{children}</StyledText>;
};
export default LeadHeading;
